.Background {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: -2vh;
  left: -2vw;
  width: 105vw;
  height: 105vh;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  //background-image: url('../../assets/images/background.jpg');
}
