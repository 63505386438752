.LetsTalkForm input, .LetsTalkForm textarea {
  background: transparent;
  border: 2px solid white;
  padding: 10px 15px;
  border-radius: 10px;
  font-family: 'Russo One', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  outline: none;
  color: white;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.Actions {
  display: flex;
  justify-content: space-between;
}
