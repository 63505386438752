.Container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  text-shadow: 2px 2px #000000;
  background: rgba(105, 16, 45, 0.92);
  display: inline-block;
  padding: 30px;
  border-radius: 10px;
  color: white;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.75);
}
