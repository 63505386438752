.Button {
  background: transparent;
  border: 3px solid #6b53b5;
  padding: 10px 25px;
  color: white;
  font-family: 'Russo One', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 15px;
  outline: none;
  border-bottom-left-radius: 50px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.Button.Danger {
  border-color: #fd3147;
}

.Button:hover {
  background: #fd3147;
  border-color: #fd3147;
}
