.Tagmark {
  width: 5rem;
  height: 3px;
  background: #080213;
  position: absolute;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  left: 0;
  top: 15px;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
}
